.welcome-container {
    display: flex;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    min-height: 325px;
}

.welcome-right {
    position: relative;
    /*min-height: 25rem;*/
    background-color: var(--card-color);
}
.welcome-right label{
    color: var(--main-text-color)
}
.welcome-separator {
    background: linear-gradient(-45deg, var(--main-red-color), var(--main-blue-color));
    position: absolute;
    height: 5px;
}

.welcome-content {
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    max-height: 100vh;
    overflow-y: auto;
}


.welcome-content p {
    font-size: 1rem;
}
.welcome-text {
    font-size: 1.25rem !important;
}

.welcome-content button {
    background-color: var(--main-blue-color);
    color: var(--alt-text-color);
    font-size: 1.25rem;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.welcome-but-container {
    margin-top: 15px;
}
.welcome-image{
    display: flex;
    flex-direction: column;
    
}

.welcome-logo {
    position: absolute;
    height: 4.5rem;
    top: -2.875rem;
    z-index: 2;
}
.welcome-container input {
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    margin: 10px 0px;
    outline: none;
    border: 1px solid var(--border-color);
    background-color: var(--card-color);
    color: var(--main-text-color)
}
.welcome-container input:user-invalid{
    outline: 1px solid var(--main-red-color);
    border: 1px solid var(--main-red-color);
}
.welcome-container input:user-valid{
    outline: 1px solid var(--main-green-color);
    border: 1px solid var(--main-green-color);
}
.login-but{
    width: 100%;
    margin-top: 10px;
}
.error-message {
    margin: 0;
    margin-bottom: 8px;
    font-size: 1rem !important; 
    color: var(--main-red-color)
}
.linkspan{
    color: var(--main-blue-color);
    text-decoration: underline;
    cursor: pointer;
}
.git-logo{
    width: 100%;
    height: 60px;
    margin-bottom: 15px;
}
.skip-button{
    border: none;
    background-color: transparent !important;
    color: var(--main-text-color) !important;
    text-decoration: underline;
}
.git-auth-content{
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.git-auth-content span{
    margin-bottom: 1rem;
}
.text-git{
    font-size: 1.25rem !important;
    text-align: center;
}

.password-tooltip {
  position: absolute;
  bottom: 110%;
  background-color: var(--card-color);
  color: #333;
  border: var(--border-color);
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.password-tooltip ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.password-tooltip li {
  font-size: 14px;
  margin-bottom: 5px;
}

.password-container{
    position: relative;
}

.password-input:user-invalid{
    outline: none !important;
    border: 1px solid var(--border-color) !important;
}
.password-input:user-valid{
    outline: none !important;
    border: 1px solid var(--border-color) !important;
}
.eye-icon{
    height: 24px ;
    cursor: pointer;
}
.flex-pass{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.politics-link{
    text-decoration: underline;
    color: var(--main-blue-color);
    cursor: pointer;
}
.flex-agree{
    display: flex;
    align-items: center;
}
.checkbox{
    width: 1.5rem !important;
    height: 1.5rem;
    padding: 0 !important;
    margin: 0 !important;
    outline: none !important;
}
.policy-modal{
    position: fixed;
    width: 50rem;
    height: 50rem;
    top: calc(50% - 25rem);
    left: calc(50% - 25rem);
    padding: 1.5rem;
    z-index: 9;
    border-radius: 10px;
    background-color: var(--card-color);
    overflow-y: auto;
}
.policy-modal pre{
    word-break: break-word;
    white-space: pre-wrap;
    margin: 0;
}
.policy-modal span{
    font-weight: 500;
    margin-left: 1.5rem;
    width: 100%;
}
.loading-page{
    width: 100vw;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.politics-cross{
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}
@media (width <= 50rem){
    .policy-modal{
        width: 96vw;
        left: 2vw;
    }
}
@media (height <= 50rem){
    .policy-modal{
        height: 96vh;
        top: 2vh;
    }
}
@media (resolution > 100dpi) {
    .welcome-container{
        transform: scale(0.9);
    }
}
 
@media (width < 25rem) {
    .welcome-container {
        width: 100vw;
    }

    .welcome-image {
        display: none;
    }
    .welcome-logo {
        width: 100vw;
    }
    .welcome-right {
        width: 100vw;
        margin-top: 50px;
    }

    .welcome-separator {
        width: 100vw;
    }

    .welcome-but-container {
        width: 100%;
    }
    .welcome-content button {
        margin: 5px;
    }
}

@media (width >= 25rem) and (width < 50rem) {
    .welcome-page {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .welcome-container {
        width: 25rem;
    }

    .welcome-image {
        display: none;
    }

    .welcome-right {
        width: 25rem;
    }

    .welcome-separator {
        width: 25rem;
    }

    .welcome-but-container {
        display: flex;
        gap: 42px;
    }

    .welcome-logo {
        width: 25rem;
    }
}

@media (width >= 50rem) {
    .welcome-page {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .welcome-container {
        width: 50rem;
    }

    .welcome-image {
        display: flex;
        flex-direction: column;
        background: linear-gradient(-45deg, var(--main-red-color), var(--main-blue-color));
        width: 25rem;
        /*min-height: 25rem;*/
    }
    .welcome-selector{
        height: 3rem;
        display: flex;
    }
    .welcome-selector button{
        flex: 1;
        color: var(--alt-text-color);
        font-size: 1.3rem;
        border: none;
        background-color: transparent;
        font-weight: 600;
        cursor: pointer;
    }
    .studentb{
        background-color: var(--main-blue-color) !important;
        border-radius: 0 1.5rem 0 0;
    }
    .professorb{
        background-color: var(--main-red-color) !important;
        border-radius: 1.5rem 0  0 0;
    }

    .welcome-image p {
        flex: 1;
        color: var(--alt-text-color);
        padding: 45px;
        margin: 0;
        font-size: 3rem;
        font-weight: 600;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    .welcome-right {
        width: 25rem;
    }

    .welcome-separator {
        width: 25rem;
    }

    .welcome-but-container {
        display: flex;
        gap: 42px;
    }

    .welcome-logo {
        width: 25rem;
    }
    .switch-reg-mode{
        display: none;
    }
}