.activity{
    width: 100%;
}
.act-container{
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
}
.act-grid-container{
    display: flex;
}
.act-dayofweek{ 
    height: 10px;
    margin: 2px 0px;
    margin-right: 5px;
    font-size: 10px;
}
.grid-item{
    position: relative;
    width: 10px;
    height: 10px;
    margin: 2px 1px;
    cursor: pointer;
}
.grid-item-0{
    background-color: var(--border-color);
}
.grid-item-25{
    background-color: var(--activity-25);
}
.grid-item-50{
    background-color: var(--activity-50);
}
.grid-item-75{
    background-color: var(--activity-75);
}
.grid-item-100{
    background-color: var(--activity-100);
}
.tooltip{
    position: absolute;
    right: 0px; 
    bottom: 120%;
    white-space: nowrap;
    background-color: var(--card-color);
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 3;
    opacity: 0;
    font-size: 0.8rem;
    transition: opacity 0.2s ease-in-out, font-size 0.2s ease-in-out; 
    pointer-events: none; 
    color: var(--main-text-color)
}

.grid-item:hover .tooltip {
    opacity: 1;
    font-size: 1rem;
  }

.act-mounth-container{
    display: flex;
    justify-content: space-around;
    margin-left: 18px;
    margin-right: 18px;
}
.act-month{
    margin: 2px;
    font-size: 10px;
}
.act-bottom{
    display: flex;
}
.activity-p{
    font-size: 12px;
    font-style: italic;
    margin: 5px 0px;
    flex: 1;
}
.act-bottom-right{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: end;
}
.act-bottom-right p{
    font-size: 12px;
    font-style: italic;
    margin: 5px 3px;
}
.nopoint{
    cursor: default;
}
.grid-hidden{
    visibility: hidden;
    opacity: 0;
    z-index: -1;
}

@media (max-width: 719px) {
    .activity{
        width: 92vw;
    }
    .act-container{
        width: 92vw;
        overflow-x: auto;
        box-sizing: border-box;
        
    }
    .act-mounth-container{
        width: 630px;
    }
}
