.main-container{
    overflow: hidden;
}
.top-menu{
    width: 100%;
    height: 45px;
    background-color: var(--card-color);
    position: relative;
    z-index: 10;
}
.sepline{
    background: var(--main-gradient);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;

}
.top-menu-content{
   width: 100%;
   height: 40px;
   display: flex;
   align-items: center;
}
.flex-start{
   flex:1;
}
.flex-center{
    flex:1;
    display: flex;
    justify-content: center;
 }
.flex-end{
    flex:1;
    display: flex;
    justify-content: end;
 }
 .topmenu-button{
   height: 25px;
   padding: 0;
   border: none;
   background-color: var(--card-color);
   margin-right: 20px;
   cursor: pointer;
   user-select: none;
 }
 .theme-icon-container{
   position: relative;
   width: 25px;
   height: 25px;
   overflow: hidden;
   cursor: pointer;
   margin-right: 20px;
 }
 .theme-icon {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   transition: transform 1s ease, opacity 1s ease;
   opacity: 1;
 }
 
 .theme-icon-1 {
   z-index: 1;
 }
 
 .theme-icon-2 {
   z-index: 0;
 }
 
 .theme-icon-active {
   z-index: 1;
   transform: translateY(0);
   opacity: 1;
 }
 
 .theme-icon-hidden-1 {
   z-index: 0;
   transform: translateY(100%); 
   opacity: 0;
 }
 .theme-icon-hidden-2 {
   z-index: 0;
   transform: translateY(-100%); 
   opacity: 0;
 }

 .topmenu-button-left{
    height: 25px;
    padding: 0;
    border: none;
    background-color: var(--card-color);
    margin-left: 20px;
    cursor: pointer;
    user-select: none;
 }

 .sidebar-container{
    width: 15rem;
    position: fixed;
    top: 45px;
    height: 100%;
    background-color: var(--card-color);
    transition: left 0.4s ease;
    z-index: 10;
    overflow-y: auto;
 }
 .sidebar-container-shown{
    left: 0;
 }
 .sidebar-container-hidden{
    left: min(-15rem, -180px);
 }
 .sidebar-user-info{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 15px;
 }
 .sidebar-user-info img{
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
 }
 .sidebar-user-info p{
    text-align: center;
 }
 .bg-blur-shown{
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    visibility: visible;
    transition: all 0.4s ease;
 }
 .bg-blur-hidden{
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease;
}
.sidemenu-level{
   z-index: 9 !important;
}
.z15-level{
   z-index: 15 !important;
}

.sidebar-separator{
    height: 2px;
    width: 100%;
    background: var(--main-gradient);
}
.sidebar-separator-end{
   height: 2px;
   width: 100%;
   background: var(--main-gradient);
   margin-bottom: 3rem;
}
.sidemenu-butt{
    padding: 10px 15px;
    cursor: pointer;
    font-size: 1.3rem;
    color: var(--main-text-color);
}
.sidemenu-butt:hover{
    border-right: 2px solid var(--main-blue-color);
}
.topmenu-button-left img{
    transition: transform 0.4s ease;
    
}
.ico-flip{
    transform: rotate(180deg);
}
.page{
   transition: margin 0.4s ease;
}
.status-bar{
   position: fixed;
   bottom: 0px;
   height: 45px;
   width: 100vw;
   backdrop-filter: blur(5px);
   z-index: 11;
   display: flex;
   align-items: center;
   padding-inline: 1rem;
   font-size: 1.3rem;
   justify-content: space-between;
}

.yellow{
   background-color: rgba(255, 192, 10, 0.7);
}
.red{
   background-color: rgba(228, 0, 57, 0.8 );
}


@media (width >= 1100px){
   .page-content{
      display: flex;
   }
   .main-bg-blur-shown{
      visibility: hidden;
      opacity: 0;
   }
   .sidebar-container{
      background-color: transparent;
      padding-right: 3px;
   }
   .sidebar-container-shown + .page{
      margin-left: 15rem;
   }
   .sidebar-separator-end{
      margin-bottom: 0px;
   }
   .sidebar-user-info{
      margin-block: 15px;
      border-radius: 10px 10px 10px 0px;
      background-color: var(--card-color);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
   }
   .sidemenu-buttons{
      border-radius: 10px 10px 10px 0px;
      background-color: var(--card-color);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      padding-block: 10px;
      margin-block: 15px;
   }   
}
