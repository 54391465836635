.profile-page{
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 50px);
    padding: 0rem 1rem;
    padding-bottom: 20px;
}
.profile-top-menu{
    width: 100%;
    height: 45px;
    background-color: var(--card-color);
    position: relative;
}
.profile-sepline{
    background: var(--main-gradient);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;

}
.profile-top-content{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}
.flex-start{
   flex:1;
}
.flex-center{
    flex:1;
    display: flex;
    justify-content: center;
 }
.flex-end{
    flex:1;
    display: flex;
    justify-content: end;
 }
 .logo{
    height: 60px;
    margin: 5px 20px 0px 20px;
    z-index: 1;
 }
 .topmenu-button{
   height: 25px;
   padding: 0;
   border: none;
   background-color: var(--card-color);
   margin-right: 20px;
   cursor: pointer;
 }
 .profile-page-content{
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    padding: 1rem;
 }
.profile-info{
    width: 50rem;
    border-radius: 10px;
    padding: 1rem 2rem;
    background-color: var(--card-color);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
    box-sizing: border-box;
}
.profile-pic-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile-pic-container p{ 
    margin: 0;
}
.profile-pic{
    width: 180px;
    height: 180px;
    border-radius: 50%;
    object-fit: cover;
}
.profile-username{
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    margin-top: 10px !important;
}
.link-container{
    display: flex;
    margin: 10px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.link-container p{
    margin: 0;
    font-size: 1.625rem;
}
.link-container a{
    text-decoration: none;
    color: var(--text-color);
    font-style: italic;
    font-weight: 300;
    font-size: 1.625rem;
}
.profile-sep{
    height: 2px;
    width: 100%;
    background: var(--main-gradient)
}
.profile-page-content h2{
    width: 100%;
    max-width: 50rem;
    font-size: 1.8rem;
    padding-left: 2rem;
    padding-top: 20px;
    margin: 0;
    margin-bottom: 10px;
    color: var(--main-text-color);
}
.profile-projects{
    width: 50rem;
    border-radius: 10px;
    padding: 1rem 2rem;
    background-color: var(--card-color);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
}
.profile-projects-content{
    display: flex;
    cursor: pointer;
}
.profile-projects-content-img{
    width: 1.5rem;
    cursor: pointer;
}
.profile-projects-content p{
    font-style: italic;
    flex:1;
    margin: 0;
}
.profile-projects-separator{
    width: 100%;
    height: 2px;
    background: var(--main-gradient);
}
.profile-projects-item{
    margin: 10px 0px;
}
.profile-link a{
    color: var(--main-text-color);
}
.prifile-image{
    position: relative; 
}
.profile-edit-but{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: var(--accent-color);
    background-image: url("./../images/edit.svg");
    background-size: 60% 60%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
}
 @media (width < 25rem) {
    .logo{
        display: none;
    }
    .profile-info{
        display: block;
        width: 100%;
        overflow: hidden;
    }
    .profile-projects{
        display: block;
        width: 100%;
    }
    .link-container{
        display: block;
    }
    .profile-page-content{
        align-items: start;
    }
 }
 @media (width >= 25rem) and (width < 52rem) {
    .profile-info{
        display: block;
        width: 100%;
    }
    .profile-projects{
        display: block;
        width: 100%;
    }
    .profile-page-content{
        align-items: start;
    }
 }