.settings-cross{
    width: 2rem;
    height: 1rem;
    cursor: pointer;
}
.settings-arrow{
    width: 1rem;
    cursor: pointer;
}
.settings-flex{
    display: flex;
    align-items: center;
}

.settings-flex p{
    flex: 1;
    margin-top: 10px;
    margin-bottom: 8px;
    font-style: italic;
    cursor: pointer;
}
.icons-container{
    display: flex;
    gap: 0.5rem;
}
.edit-butt{
    width: 100%;
    border: none;
    background-color: var(--main-blue-color);
    color: var(--alt-text-color);
    padding: 0.5rem 1rem;
    cursor: pointer;
}
.delete-butt{
    width: 100%;
    border: none;
    background-color: var(--main-red-color);
    color: var(--alt-text-color);
    padding: 0.5rem 1rem;
    cursor: pointer;
}
.buttons-tile{
    width: 45rem;
    padding: 1.2rem;
    background-color: var(--card-color);
    border-radius: 10px;
    margin: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    gap: 0.7rem
}
.settings-modal{
    position: fixed;
    width: 45rem;
    top: calc(50% - 12rem);
    left: calc(50% - 22.5rem);
    padding: 1.5rem;
    z-index: 10;
    border-radius: 10px;
    background-color: var(--card-color);
}
.settings-modal span{
    color: var(--main-text-color);
}
.settings-modal h2{
    margin-left: 0 !important;
    margin-top: 0 !important;
}
.settings-modal input{
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    margin: 10px 0px;
    outline: none;
    border: 1px solid var(--border-color);
    background-color: var(--card-color);
    color: var(--main-text-color);
    border-radius: 10px;
}
.settings-modal textarea{
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    margin: 10px 0px;
    outline: none;
    border: 1px solid var(--border-color);
    background-color: var(--card-color);
    color: var(--main-text-color);
    border-radius: 10px;
    resize: none;
    height: 10rem;
}
.settings-modal button{
    border: none;
    border-radius: 10px;
    background-color: var(--main-blue-color);
    color: var(--alt-text-color);
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-weight: 600;
    flex: 1;
}
.settings-flex-butt{
    display: flex;
    gap: 1rem;
}
.blocked-del-butt{
    background-color: var(--border-color) !important;
    cursor: no-drop !important;
}
.active-del-butt{
    background-color: var(--main-red-color) !important;
}
.invite-code {
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-family: monospace;
    font-size: 1.5rem;
}
.code-container{
    margin-bottom: 20px;
}
@media (width < 18.75rem){
    .settings-flex-butt{
        flex-direction: column;
        gap: 0.5rem;
    }

}
@media (width < 47rem) {
    .buttons-tile{
        width: 100%;
    }
    .settings-modal{
        width: calc(100vw - 2rem);
        left: calc(50% - 50vw + 1rem);
    }
    .invite-code{
        font-size: 1rem;
    }
    .invite-code, .copied-text {
        display: block;
    }
    .invite-code{
        margin-bottom: 5px;
    }
    .code-container{
        overflow: hidden;
    }
}