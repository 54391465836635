.messenger-page{
    width: 100vw;
    height: calc(100vh - 45px);
    height: calc(100dvh - 45px);
    display: flex;
    overflow: auto;
}
.a *{
    outline: 1px solid red;
}
.chats-list{
    height: calc(100vh - 45px);
    height: calc(100dvh - 45px);
    overflow: auto;
    padding: 0.8rem;
    width: 30rem;
    border-right: 2px solid var(--main-blue-color);
    z-index: 2;
}
.chat-list-tile{
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 6.25rem;
    padding: 10px;
    border-radius: 1.75rem;
    cursor: pointer;
    background-color: var(--card-color);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.8rem;
    min-width: min-content;    
}
.active-chat-list-tile{
    outline: 2px solid var(--main-blue-color);
}
.chat-list-tile p{
    margin: 5px 0px;
   
}
.chat-list-tile h2{
    font-size: 1.2rem;
    margin: 5px 0px;
    color: var(--main-text-color);
}
.chat-list-tile-content{
    flex: 1;
}
.chat-list-tile-new{
    width: 2.2rem;
    height: 2.2rem;
    padding: 0.3rem;
    border-radius: 50%;
    border: 2px solid var(--main-blue-color);
    font-weight: 600;
    color: var(--main-blue-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatIco{
    width: 4rem;
    flex-shrink: 0;
}
.chat-list-tile-lastmess{
    display: flex;
    align-items: center;   
}
.chat-list-tile-lastmess-combined {
    display: inline-block;
    max-width: calc(28rem - 5rem - 9rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--main-text-color);
}
  
.chat-list-tile-lastmess-user {
    font-weight: 300; 
    margin-right: 0.5rem !important;
}
  
.chat-list-tile-lastmess-mess {
    font-weight: 100;
}
.chat-list-tile-lastmess-date{
    font-weight: 100;
    margin-left: 0.5rem !important;
    white-space: nowrap;
}
.chat-window{
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}
.chat-top{
    height: 3rem;
    padding: 0px 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    box-shadow: 0px 1rem 1.5rem 12px var(--bg-color);
    text-wrap: nowrap;
    max-width: 100vw;
    overflow: hidden;
    z-index: 1;
    transition: box-shadow 1s ease !important;
    
}
.chat-top h1{ 
    font-size: 2rem;
    font-weight: 500;
    color: var(--main-text-color);
    width: 400px;
}
.back-arr{
    height: 1.2rem;
    cursor: pointer;
}
.chat-center{
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 1rem;
}
.chat-end{
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--card-color);
    padding: 0 0.8rem;
}
.chat-input {
  width: 100%;
  min-height: 3rem;
  max-height: 9rem; 
  padding: 0.92rem;
  font-size: 1rem;
  border: none;
  overflow-y: auto;
  resize: none; 
  box-sizing: border-box;
  outline: none;
  background-color: transparent;
  color: var(--main-text-color);
  scrollbar-width: none; 
}
.send-arr{
    height: 2.5rem;
    cursor: pointer;
}
.user-pic{
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
}
.message-group-l{
    display: flex;
    margin-bottom: 0.8rem;
}
.message-group-r{
    display: flex;
    margin-bottom: 0.8rem;
    flex-direction: row-reverse;
}
.userpic-container{
    width: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
}
.messages-container{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    max-width: 60rem;
}
.messages-container {
    margin: 0;
}
.message{
    padding: 0.7rem;
    background-color: var(--card-color);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: fit-content;
    color: var(--main-text-color); 
    overflow: hidden;
}
.message-l{
    border-radius: 0px 10px 10px 10px;
}
.message-r{
    border-radius: 10px 0px 10px 10px;
}
.message-text{
    align-self: flex-end;
    color: var(--main-text-color);
    word-break: break-word;
    white-space: pre-wrap;
    margin: 0;
}
.message-container-l{
    display: flex;
}
.message-container-r{
    display: flex;
    flex-direction: row-reverse;
    align-self: flex-end;
}
.message-author{
    margin: 0;
    font-weight: 500;
    color: var(--main-blue-color);
    cursor: pointer;
}
.text-right{
    text-align: end;
}
.message-time{
    padding: 0.7rem;
    font-weight: 100;
    font-style: italic;
    color: var(--main-text-color);
}
.date-sep{
    width: 100%;
    text-align: center;
    padding: 0.3rem;
    color: var(--main-text-color);
}
.unread-sep{
    width: 100%;
    text-align: center;
    padding: 0.3rem;
    color: var(--main-text-color);
}
.no-chat-text{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 2rem;
    color: var(--main-text-color)
}

@media (width < 1000px) {
    .chats-list {
      width: 100%;
      border: none;
    }
}
@media (1000px < width < 1240px) {
    .chat-list-pinned{
      width: 100%;
      border: none;
    }
}
