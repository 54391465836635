.kanban-page{
    width: 100vw;
    height: calc(100vh - 45px);
    height: calc(100dvh - 45px);
    box-sizing: border-box;
    overflow: auto;
}
.kanban-main-container{
   padding: 1rem;
   padding-bottom: 0;
   display: flex;
   align-items: flex-start;
   width: max-content;
}
.kanban-main-container::-webkit-scrollbar{
    height: 11px;
}
.kanban-sprint{
    max-height: none !important;
}
.dots{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.kanban-row{
    width: 20.35rem;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.8rem;
    padding: 0 0.8rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 2rem;
}

.kanban-row-top{
    display: flex;
    top: 0;
    width: 100%;
    padding-block: 0.8rem;
    font-size: 1.5rem;
}

.kanban-row-top p{
    flex:1;
    margin: 0;
    cursor: text;
    word-break: break-word;
    text-wrap: balance;
    hyphens: auto;
    -webkit-hyphens: auto;
}
.kanban-add-cont{
    display: flex;
    top: 0;
    width: 18.75rem;
    padding: 0.2rem;
    border-radius: 1.75rem;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}
.kanban-add-cont p{
    margin: 0;
    margin-right: 5px;
}

.kanban-card{
    width: 100%;
    max-width: 18.75rem;
    background-color: var(--card-color);
    padding: 0.8rem 0.8rem;
    
    border-radius: 8px;
    border: 1px solid var(--border-color);
    align-items: center;
    user-select: none;
    margin-bottom: 0.5rem;
}
.card-top{
    display: flex;
    margin-bottom: 0.5rem;
}
.card-top p{
    flex: 1;
}
.card-name{
    font-size: 1.5rem;
    margin: 0;
    cursor: text;
    word-break: break-word;
    text-wrap: balance;
    hyphens: auto;
    -webkit-hyphens: auto;
}
.card-info{
    font-style: italic;
    font-weight: 300;
    margin: 0;
    margin-top: 0.35rem;
}

.cards-container{
    display: flex;
    flex-direction: column;
    position: relative;
    height: max-content;
}
.more-butt{
    width: 2rem;
}
.add-column{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background: var(--main-gradient);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.plus-column{
    width: 4rem;
}
.chat-icon{
    height: 1.2rem;
    margin-right: 5px;
}
.kanban-add-text{
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 300;  
    cursor: pointer; 
}
.pointer{
    cursor: pointer;
}
.drop-menu{
    margin: 0;
    padding: 0;
    width: 2rem;
    height: 2rem;
    position: relative;
}
.ddmenu{
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    width: 10rem;
    background-color: var(--card-color);
    z-index: 2;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    overflow: hidden;
    cursor: auto;
}
.ddmenu button{
    width: 100%;
    border: none;
    background-color: var(--card-color);
    color: var(--main-text-color);
    padding: 0.4rem 0.8rem;
    font-size: 1rem;
    cursor: pointer;
    text-align: start;
}
.ddmenu button:hover{
    background-color: var(--bg-color);
}
.delete-button:hover{
    background-color: var(--main-red-color) !important;
    color: var(--alt-text-color)
}
.marginb{
    margin-bottom: 10px !important;
}
.delete-modal{
    position: fixed;
    width: 18.75rem;
    top: calc(50% - 9rem);
    left: calc(50% - 9.375rem);
    padding: 1.5rem;
    border-radius: 10px;
    background-color: var(--card-color);
}
.delete-modal button{
    border: 1px solid var(--border-color);
    background-color: var(--card-color);
    color: var(--main-text-color);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.1rem;
}
.delete-modal p{
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 20px;
}
.flex-butt{
    display: flex;
    justify-content: space-between;
}
.deletem-button{
    background-color: var(--main-red-color) !important;
    color: var(--alt-text-color) !important;
    border: 1px solid transparent !important;
}
.delete-modal textarea{
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box; 
    margin: 10px 0px;
    outline: none;
    border: 1px solid var(--border-color);
    background-color: var(--card-color);
    color: var(--main-text-color);
    margin-bottom: 20px;
    border-radius: 10px;
    resize: none;
    height: 5rem;
}
.modal-edit-text{
    margin: 0 !important;
}
.drag{
    cursor:grab !important;
}
.normal-cursor{
    cursor: default !important;
}

.standard-colors {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}
  
.color-box {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
  
.custom-color {
  display: flex;
  align-items: center;
  gap: 10px;
}
.color-input {
  width: 40px; 
  height: 40px; 
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  margin-bottom: 10px;
}
