.commit{
    display: flex;
    width: 100%;
    padding: 10px;
    align-items: center;
}
.commit:not(:last-child){
    border-bottom: 1px solid var(--border-color);
}
.commit-img{
    width: 2rem;
    height: 2rem;
}
.commit-left{
    flex: 1;
}
.commit-info img{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: 5px;
}
.commit-info{
    display: flex;
    align-items: center;
}
.commit-info p {
    margin: 0;
    padding: 4px 0px;
    font-weight: 300;
    display: inline;
}
.commit-name{
    padding: 4px 0px;
    margin: 0;
    font-weight: 600;
    display: inline;
}
.no-deco{
    text-decoration: none;
    color: var(--text-color);
    
}
.git-not-auth-container button{
    width: auto;
    background-color: var(--main-blue-color);
    color: var(--alt-text-color);
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}
.git-not-auth-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
