.activity-page{
    width: 100vw;
    height: calc(100vh - 45px);
    height: calc(100dvh - 45px);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    overflow: auto;
    padding: 0rem 1rem;
}
.activity-page-container{
    padding-bottom: 10px;
}

.commits-tile{
    width: 720px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    background-color: var(--card-color);
    border-radius: 10px;
    margin: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.commits-controls-container{
    width: 100%;
    display: flex;
    gap: 3%;
    justify-content: space-between;
    margin-bottom: 10px;
}
.commits-controls-container p{
    margin: 0;
    margin-bottom: 5px;
}
.year{
    flex: 0 0 32%;
}
.member{
    flex: 0 0 64%;
}
.repo{
    flex: 1
}
.flex1{
    flex: 1;
}
.commits{
    display: block;
    width: 100%;
}
.commits-date-container{
    border: 1px solid var(--border-color);
    border-radius: 10px;
}

.last-activity-tile{
    padding-left: 1rem;
    padding-right: 1.5rem;
    background-color: var(--card-color);
    border-radius: 10px;
    margin: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.sprints-tile{
    width: 720px;
    padding: 1rem;
    background-color: var(--card-color);
    border-radius: 10px;
    margin: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.sprints-tile button{
    width: 100%;
    background-color: var(--main-blue-color);
    color: var(--alt-text-color);
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: none;
    cursor: pointer;

}
.sprints-flex{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sprints-flex-img{
    cursor: pointer;
    width: 2rem;
}
.sprints-flex p{
    flex: 1;
    margin-top: 10px;
    margin-bottom: 8px;
    font-style: italic;
}

.grad-separator{
    height: 2px;
    background: var(--main-gradient);
}
@media (width < 730px) {
    .commits-controls-container{
        display: block;
    }
    .commits-tile{
        display: block;
        width: 100%;
    }
    .last-activity-tile{
        width: 100%;
    }
    .sprints-tile{
        width: 100%;
    }
    .controls-block{
        margin-bottom: 8px;
    }
}

@media ( 1725px > width >= 1490px){
    .activity-pinned{
       display: block !important;
       gap: 1rem
    }

}
@media (width >= 1490px ){
    .activity-page-container{
        display: flex;
        gap: 1rem
    }
}

