html, body, head {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
*,*::before,*::after {
  box-sizing: border-box;
  font: inherit;
  /*outline: 1px solid red !important;*/
  }

body { 
  font-family: 'Roboto Flex', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  /*transition: background-color 1s ease;*/
}
/*Для переключения темы*/

.all-trans{
  transition: color var(--main-theme-transition), background-color var(--main-theme-transition);
}
.svg-trans{
  transition: fill var(--main-theme-transition), stroke var(--main-theme-transition);
}
.no-trans{
  transition: none !important;
}

body p{
  color: var(--main-text-color);
  /*transition: color 1s ease;*/
} 
body span{
  color: var(--main-text-color);
}

body h2{
  font-weight: 600;
  font-size: 1.8rem;
  margin: 20px 0 10px 2rem;
  color: var(--main-text-color)
}
.main-button{
  background-color: var(--main-blue-color);
  color: var(--alt-text-color);
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --main-theme-transition: 1s ease;
}
/*
:root{
  --main-blue-color: #005AAA;
  --main-red-color: #E4003A;
  --main-text-color:#000000;
  --alt-text-color:#ffffff;
  --bg-color:#F7F9FA;
  --card-color:#FFFFFF;
  --border-color:#D9D9D9;
  --main-gradient: linear-gradient(270deg,var(--main-red-color), var(--main-blue-color));
  --activity-100: #005AAA;
  --activity-75: #337BCC;
  --activity-50: #66A3E6;
  --activity-25: #99C8FF;
  --main-green-color: #009A49;
}
*/
@supports (-moz-appearance: none) {
  * {
      scrollbar-width: thin;
      scrollbar-color: var(--border-color) var(--bg-color);
      transition: scrollbar-color var(--main-theme-transition);
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg,var(--main-red-color), var(--main-blue-color)); 
  border-radius: 5px;
  cursor: pointer;
}



@media (width < 25rem) {
  html {
    font-size: 0.7rem; 
  }
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  html, body, head {
    overflow: auto;
  }
}
@media (width >= 25rem) and (width < 37.5rem) {
  html {
    font-size: 0.9rem; 
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  html, body, head {
    overflow: auto;
  }
}
